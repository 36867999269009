<!--
 * @Descripttion:
 * @version:
 * @Author: 贾峰
 * @Date: 2019-11-04 18:07:25
 * @LastEditors: 霍铁樱
 * @LastEditTime: 2020-11-16 19:40:20
 -->
<template>
  <div class="mianpage">
    <div class="sharepic">
    </div>
    <div class="qrimg">
      <img :src="qrcode"
           v-if="showmodal"
           alt="二维码">
      <md-icon v-else
               class="spinnericon"
               name="spinner"
               size="lg"
               style="-webkit-filter:invert(1)"></md-icon>
    </div>

  </div>
</template>

<script>
import { getQRcode, selectZJ } from '@/api/agent/agent'
import config from '@/config'
import { wechatshare } from '@/lib/wechat_share'
import { getStorage } from '@/lib/util'
const { redirect_uri } = config
export default {
  name: 'index',
  data () {
    return {
      showmodal: false,
      qrcode: '',
      zjlist: [],
      degreeno: '',
      degreename: '',
      degreelevel: ''
    }
  },
  created () {
    this.getzj()
  },
  // mounted () {
  //   this.share()
  // },
  methods: {
    addqr () {
      getQRcode({ degreeno: this.degreeno, degreename: this.degreename, degreelevel: this.degreelevel }).then(res => {
        this.qrcode = 'data:image/png;base64,' + res.data.data
        this.showmodal = true
      })
    },
    getzj () {
      selectZJ().then((res) => {
        if(res.data.data.length==0){
            Toast.failed('您当前没有职级不能实现增员！')
            return
        }
        this.zjlist = res.data.data
        this.degreeno = this.zjlist[0].degreeno
        this.degreename = this.zjlist[0].degreename
        this.degreelevel = this.zjlist[0].degreelevel
        this.addqr()
        this.share()
      })
    },
    share () {
      let info = getStorage('u_s', {})
      let shareurl = ''
      if (this.degreeno != '') {
        shareurl = `${redirect_uri}/addmember?tjempno=${info.empno}&degreeno=${this.degreeno}&degreename=${this.degreename}&degreelevel=${this.degreelevel}`
      } else {
        shareurl = `${redirect_uri}/addmember?tjempno=${info.empno}`
      }
      console.log(shareurl)

      wechatshare(
        '增员邀请',
        `您的好友${info.nickname}邀请您加入团队`,
        info.headimg,
        encodeURI(shareurl),
        redirect_uri
      )
    }
  }
}
</script>

<style scoped lang="stylus">
.mianpage {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background: url('~@/assets/agent/img/increase.jpg') no-repeat;
  background-size: 100% 100%;
}

.sharepic {
  width: 100%;
  height: 14.4vh;
  position: absolute;
  left: 0;
  top: 0;
  background: url('~@/assets/agent/img/sharebg.png') no-repeat right top;
  background-size: contain;
}

.addipctop {
  font-size: 14px;
  color: white;
  margin: 9.1vh 10vw 0;
  line-height: 0.9rem;
}

.addipcbottom {
  font-size: 14px;
  color: white;
  margin: 2.5vh 10vw 0;
  line-height: 0.9rem;

  .md-button {
    height: 3.6vh;
    color: #2755A5;
    margin: 0 0.8vw 0;
  }

  .md-button.default::after {
    z-index: 0;
  }
}

.modal {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  background: url('~@/assets/agent/img/posterbg.png') no-repeat;
  background-size: 100% 100%;
}

.qrimg {
  width: 37%;
  height: 24%;
  position: absolute;
  top: 44%;
  left: 32%;

  img {
    width: 100%;
    height: auto;
  }

  .spinnericon {
    position: absolute;
    top: 43%;
    width: 100%;
    height: 17%;
  }

  span {
    font-size: 0.4rem !important;
    display: inline-block;
    width: 100%;
    position: relative;
    text-align: center;
    color: #fff;
    margin-top: 0.4rem;
  }
}

.closebtn {
  display: flex;
  justify-content: center;
  height: 14vh;
  align-items: center;

  img {
    width: 7vw;
    height: 7vw;
  }
}

.select {
  margin-top: 36vh;
  padding: 0 3%;

  ul {
    text-align: center;
  }
}

.select li {
  display: inline-block;
  /* width: 25%; */
  /* height: 1.6rem; */
  color: #fff;
  border: 1px solid #fff;
  border-radius: 0.4rem;
  text-align: center;
  /* line-height: 1.6rem; */
  margin: 0.2rem;
  padding: 0.1rem 0.3rem;
}

.select .active {
  background: #ffffff;
  color: #0F0609;
}

.guide {
  font-size: 1.2rem;
  position: relative;
  animation: anim 1.2s ease-out infinite;
  // margin-right: .4rem;
}

@keyframes anim {
  0% {
    top: 0;
  }

  50% {
    top: 0.4rem;
  }

  100% {
    top: 0;
  }
}

.tip {
  color: #ffffff;
  padding: 3%;
  text-align: center;
}
</style>
